<template>
  <main>
    <div>
      <h1 class="text-2xl font-bold">Quick Access</h1>
      <div class="mt-10 mx-6 pb-5">
        <div class="w-full xl:my-4 xl:px-4 mt-5">
          <div class="w-3/12 xl:my-1 xl:px-4">
            <div
              class="w-full border-2 border-gray-50 flex flex-col  bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div class="bg-white px-6 py-4">
                <div class="flex items-center pt-3">
                  <div class="">
                    <p class="font-bold text-blue-800">
                      Terminals
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      view terminal related links
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <router-link to="/terminals" class="text-sm cursor-pointer">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      All Terminals >
                    </div>
                  </div>
                </router-link>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 hover:bg-blue-800 hover:bg-blue-800 text-sm text-blue-800 hover:text-white cursor-pointer rounded-md my-1 mx-2"
              >
                <router-link
                  to="/terminal-pricing"
                  class="text-sm cursor-pointer"
                >
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      Terminal Pricing >
                    </div>
                  </div>
                </router-link>
              </div>
              <div
                class="bg-blue-50 hover:bg-blue-800 px-6 py-2 hover:bg-blue-800 text-sm text-blue-800 hover:text-white cursor-pointer rounded-md my-1 mx-2"
              >
                <router-link to="/terminal-requests-all">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      Terminal Requests >
                    </div>
                  </div>
                </router-link>
              </div>
              <div
                class="bg-blue-50 hover:bg-blue-800 px-6 py-2 hover:bg-blue-800 text-sm text-blue-800 hover:text-white cursor-pointer rounded-md my-1 mx-2"
              >
                <router-link to="/pos-terminal-receipts">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      POS Terminal Receipts >
                    </div>
                  </div>
                </router-link>
              </div>
              <div
                class="bg-blue-50 hover:bg-blue-800 px-6 py-2 hover:bg-blue-800 text-sm text-blue-800 hover:text-white cursor-pointer rounded-md my-1 mx-2"
              >
                <router-link to="/terminal-deployments">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      Terminal Deployments >
                    </div>
                  </div>
                </router-link>
              </div>
              <div
                class="bg-blue-50 hover:bg-blue-800 px-6 py-2 hover:bg-blue-800 text-sm text-blue-800 hover:text-white cursor-pointer rounded-md my-1 mx-2"
              >
                <router-link to="/terminal-requests-key-accounts-view">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      Terminal Request - Key Accounts >
                    </div>
                  </div>
                </router-link>
              </div>
              <div
                class="bg-blue-50 hover:bg-blue-800 px-6 py-2 hover:bg-blue-800 text-sm text-blue-800 hover:text-white cursor-pointer rounded-md my-1 mx-2"
              >
                <router-link to="/terminal-requests-telesales-view">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      Terminal Request - Telesales >
                    </div>
                  </div>
                </router-link>
              </div>
              <div
                class="bg-blue-50 hover:bg-blue-800 px-6 py-2 hover:bg-blue-800 text-sm text-blue-800 hover:text-white cursor-pointer rounded-md my-1 mx-2"
              >
                <router-link to="/terminal-requests-isa-view">
                  <div class="flex items-center w-full">
                    <div class="w-full">
                      Terminal Request - ISA >
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="w-3/12 xl:my-1 xl:px-4">
            <div
              class="w-full border-2 border-gray-50 flex flex-col bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div class="bg-white px-6 py-4">
                <div class="flex items-center pt-3">
                  <div class="">
                    <p class="font-bold text-blue-800">
                      Portals
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      view lists of all portals
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'TerminalDeliveryPortal',
                        })
                      "
                    >
                      Terminal Delivery Portal
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'Upload',
                        })
                      "
                    >
                      Upload Portal
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'TransactionMonitoringPortal',
                        })
                      "
                    >
                      Transaction Monitoring Portal
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'FundsTransferProviderPortal',
                        })
                      "
                    >
                      Funds Transfer Provider Portal
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-3/12 xl:my-1 xl:px-4">
            <div
              class="w-full border-2 border-gray-50 flex flex-col bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div class="bg-white px-6 py-4">
                <div class="flex items-center pt-3">
                  <div class="">
                    <p class="font-bold text-blue-800">
                      Accounts
                    </p>
                    <p class="text-sm text-gray-700 mt-1">
                      view lists of all accounts
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'BankAccounts',
                        })
                      "
                    >
                      Bank Accounts
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'SettlementAccounts',
                        })
                      "
                    >
                      Settlement Accounts
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'FixedAccounts',
                        })
                      "
                    >
                      Fixed Accounts
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="bg-blue-50 px-6 py-2 text-blue-800 hover:bg-blue-800 text-sm cursor-pointer hover:text-white rounded-md my-1 mx-2"
              >
                <div class="flex items-center w-full">
                  <div class="w-full">
                    <p
                      @click="
                        $router.push({
                          name: 'FixedAccountBlacklisted',
                        })
                      "
                    >
                      Fixed Account (Blacklisted)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
import { mapGetters } from "vuex";
import moment from "moment";
import authorizedUsers from "../config/authUsers";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      user: false,
    };
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  computed: {
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA().email;
      return authorizedUsers.includes(userEmail);
    },
  },
  mounted() {},
};
</script>
